




import userManager from "@/services/userManager";
import Vue from "vue";
export default Vue.extend({
  created() {
    let url = "/app/settings/payment_transactions";
    if (userManager.isUserMobile()) {
      url = "/app/settings/payment_transactions_m";
    }
    this.$router.replace(url);
  },
});
